<template>
    <div>
        <v-layout wrap justify-center>
            <v-flex xs10 py-12>
                <v-card>
                    <v-flex xs12>pendingAmount={{ pendingAmount }}
              <v-layout wrap pt-2 pb-4>
                <v-flex xs12 lg3 pr-lg-1>
                  <v-select
                    :items="paymentTypeList"
                    v-model="paytype"
                    label="Select Item"
                    dense
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 lg3 pt-4 pt-lg-0 pr-lg-1>
                  <v-text-field
                    v-model="purchaseAmount"
                    dense
                    :label="purchaseAmount"
                    outlined
                    hide-details
                    type="number"
                    @change="addValues()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg3 pt-2 pt-lg-0>
                  <v-btn
                    block
                    dark
                    color="#766BC0"
                    dense
                    @click="addValues()"
                  >
                    Add <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 py-2>
              <v-card flat>{{ itemz }}
                <v-layout wrap>
                  <v-flex xs12 pt-1 v-for="(item, i) in itemz" :key="i">
                    <v-layout wrap pt-2>
                      <v-flex xs12 lg3 pr-lg-1>
                        <v-text-field
                          v-model="item.purchaseAmount"
                          label=" Item"
                          solo
                          dense
                          class="my-0 py-0"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.paytype"
                          dense
                          type="text"
                          :label="paytype"
                          solo
                          hide-details
                        ></v-text-field>
                       
                      </v-flex>

                      <v-flex xs12 lg3 pt-2 pt-lg-0>
                        <v-btn
                          block
                          dark
                          style="background-color: #766BC0"
                          dense
                          @click="itemz.splice(i, 1), calcPrice(item.purchaseAmount)"
                        >
                          Remove
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
 paymentTypeList:[
    "cash","cheque","gpay"
 ],
 paytype:"",
 pendingAmount:"1000",
 purchaseAmount:"",
 itemz2:[],
 singleitem:{
    paytype:"",
    purchaseAmount:0,
 },
    };
  },
  methods: {
  
 //add button
 
    addValues() {
        console.log("0")

      if (
        this.paytype != null &&
        // this.purchaseAmount != null &&
        this.purchaseAmount != null 
        // this.unitType != null
      ) {
        this.singleitem.paytype = this.paytype;
        this.singleitem.purchaseAmount = this.purchaseAmount;
        console.log("type=",this.paytype);
        console.log("purchaseAmount=",this.purchaseAmount);

        this.calcPrice2(this.purchaseAmount);
        console.log("1")


      } else {
        this.snackbar = true;
        this.msg = "please fill your form";
      }
    },

    addVariant() {
        console.log("3")

      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      //////
      this.paytype = null;
      this.purchaseAmount = null;
      console.log("itemz==",this.itemz)

      // this.amt = null;
    },

    calcPrice2(val1) {
        console.log("2")
      this.pendingAmount = this.pendingAmount-val1;
      console.log("pendingAmount in calprice2=",this.pendingAmount)

      this.addVariant();

    },

    calcPrice(vall) {
    //   var itemtotalamt1 = this.pendingAmount;
    //   for (let i = 0; i < this.itemz.length; i++) {
    //     if ( this.itemz[i].purchaseAmount) {
        console.log("val=",vall)
        console.log("pendingAmount-before-remove=",this.pendingAmount)

            this.pendingAmount = parseFloat(this.pendingAmount)+parseFloat(vall);
         
    //     }
    //   }
    //   this.pendingAmount = itemtotalamt1;
      console.log("pendingamt after remove=",this.pendingAmount);

    },

  },
};
</script>
<style scoped>